.account-layout {
  background-color: #fafbfe;
  min-height: 100vh;
  .open-width {
    width: calc(100% - 300px);
  }
  .close-width {
    width: calc(100% - 150px);
  }

  .input-wrapper input,
  .input-wrapper .form-control,
  .input-wrapper .form-select {
    padding-right: 45px !important;
    box-shadow: 0 0 8px 2px rgba(87, 86, 86, 0.27);
    &:focus {
      background-color: white;
    }

    &:disabled {
      background-color: rgba(247, 231, 253, 0.286);
    }
  }

  .input-wrapper {
    position: relative;
    .filter-icon {
      width: 27px;
      position: absolute;
      right: 16px;
      top: 50%;
      transform: translateY(-50%);
      cursor: pointer;
    }
  }
}

.admin-panel-heading {
  width: max-content;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

@media screen and (max-width: 767px) {
  .account-layout {
    .side-nav-wrapper {
      position: fixed;
      z-index: 99;
      .open-shadow {
        box-shadow: 0 0 0 2000px rgba(0, 0, 0, 0.523);
      }
    }
    .open-width {
      width: 100%;
    }
    .close-width {
      width: 100%;
    }
  }
}

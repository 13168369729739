@import url("https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Nunito:ital,wght@0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");
@import "./variables";

// @import "./components/OnboardingSlides/style";
// @import "./components/Loading/style";
// @import "./routing/layout-routes/auth-lr/style";

@import "../node_modules/bootstrap/scss/bootstrap";

* {
  font-family: "Nunito", sans-serif;
}

h1,
.title,
.subtitle {
  font-family: "Poppins", sans-serif;
}

.App {
  min-height: 100vh;
  text-align: center;
}

.title {
  font-weight: 500;
  font-size: xx-large;
  color: $big-text-color;
  line-height: 1.2;
}

.subtitle {
  font-size: 15px;
}

.dark-blue-text {
  color: $big-text-color;
}

.round-button {
  border-radius: 50%;
  padding: 10px;
  border: none;
  background-color: #ced1db;
  box-shadow: 0px 2px rgb(100, 100, 100);
}

.form-label {
  font-weight: 700;
  color: $big-text-color;
}

.form-control {
  padding: 16px;
}

.form-select {
  padding: 16px;
}

.primary-button {
  background-color: $primary-color;
  border: 0;
  border-radius: 8px;
  font-weight: 700;
  font-size: 20px;
  padding: 12px 20px;
  box-shadow: 0px 6px $primary-shadow-color;
  color: white !important;

  &:disabled {
    opacity: 0.8;
    cursor: not-allowed;
  }

  &-red {
    @extend .primary-button;
    background-color: rgb(247, 57, 57);
    box-shadow: 0px 6px rgb(194, 8, 8);
  }

  &-grey {
    @extend .primary-button;
    background-color: rgb(127, 127, 127);
    box-shadow: 0px 6px rgb(71, 71, 71);
  }

  &-yellow {
    @extend .primary-button;
    background-color: #ffb000;
    box-shadow: 0px 6px #D69400;
    ;
  }

  &-green {
    @extend .primary-button;
    background-color: #4caf50;
    box-shadow: 0px 6px #30824a;
  }

}

.primary-outline-button {
  border: 2px $primary-color solid;
  border-radius: 8px;
  font-weight: 700;
  font-size: 20px;
  padding: 12px 20px;
  background: transparent;
  color: $primary-color;

  &:disabled {
    opacity: 0.8;
    cursor: not-allowed;
  }
}

.primary-transparent-button {
  border: 0;
  border-radius: 8px;
  font-weight: 700;
  font-size: 20px;
  padding: 0;
  background: transparent;
  color: $primary-color;
}

.secondary-button {
  background-color: $secondary-color;
  border: 0;
  border-radius: 8px;
  font-size: 16px;
  padding: 14px 20px;
  box-shadow: 0px 6px $secondary-shadow-color;
  color: white;
}

.primary-dropdown-button {
  button {
    @extend .primary-button;
    background-color: $primary-color !important;

    &:hover,
    &:focus,
    &:active {
      box-shadow: 0px 6px $primary-shadow-color !important;
    }
  }

  .dropdown-item {
    font-weight: 700;
    font-size: 16px;
    padding: 12px 20px;
    color: $primary-color !important;
    background: white;

    &:hover {
      background-color: $primary-color !important;
      color: white !important;
    }
  }

  .dropdown-menu {
    padding: 0 !important;
    box-shadow: 0 0px 10px 3px #00000042;
    border-radius: 8px !important;
    overflow: clip;
  }

  &-yellow {
    @extend .primary-dropdown-button;

    button {
      background-color: #ffb000 !important;
      box-shadow: 0px 6px #D69400 !important;

      &:hover,
      &:focus,
      &:active {
        box-shadow: 0px 6px #D69400 !important;
      }
    }
  }
}

.nav-link-selected {
  background-color: $primary-color;
  color: white;
  min-width: 175px;
  border: 0;
  border-radius: 8px;
  font-size: 16px;
  padding: 12px;
}

a {
  color: $primary-color;
  font-weight: 600;

  &:hover {
    color: $primary-color-dark;
  }
}

.link-button {
  text-decoration: none;

  &:hover {
    color: white;
  }
}

input,
.form-control,
.form-select {
  border: 0;
  box-shadow: 0 0 0 2px #5e5e5e;

  &:focus {
    background-color: rgba(247, 231, 253, 0.286);
    box-shadow: 0px 0px 0px 2px $primary-color !important;
  }

  &:focus-visible {
    outline: none;
  }

  &:disabled {
    background-color: rgba(247, 231, 253, 0.286);
  }
}

.modal-open {
  overflow: auto !important;
  padding-right: 0 !important;
}

@media (max-width: 575px) {
  .profile-picture-container .primary-button {
    margin: 16px 0px;
  }

  .profile-picture-container .primary-transparent-button {
    margin-right: 10px;
  }
}

@font-face {
  font-family: "satoshi-medium";
  src: url("./utils/fonts/satoshi/Satoshi-Medium.otf");
}

@font-face {
  font-family: "satoshi";
  src: url("./utils/fonts/satoshi/Satoshi-Variable.ttf");
}

.font-satoshi {
  font-family: "satoshi" !important;
}

.font-satoshi-medium {
  font-family: "satoshi-medium" !important;
}

.border-blue {
  border: 1px solid #05c7f2;
}

.border-orange {
  border: 1px solid #fa8431;
}

.border-yellow {
  border: 1px solid #ffb000;
}

@include media-breakpoint-up(sm) {
  .w-sm-25 {
    width: 25% !important;
  }

  .w-sm-50 {
    width: 50% !important;
  }

  .w-sm-75 {
    width: 75% !important;
  }

  .w-sm-100 {
    width: 100% !important;
  }
}

@include media-breakpoint-up(md) {
  .w-md-auto {
    width: auto !important;
  }

  .w-md-25 {
    width: 25% !important;
  }

  .w-md-30 {
    width: 30% !important;
  }

  .w-md-35 {
    width: 35% !important;
  }

  .w-md-40 {
    width: 40% !important;
  }

  .w-md-50 {
    width: 50% !important;
  }

  .w-md-60 {
    width: 60% !important;
  }

  .w-md-65 {
    width: 65% !important;
  }

  .w-md-70 {
    width: 70% !important;
  }

  .w-md-75 {
    width: 75% !important;
  }

  .w-md-100 {
    width: 100% !important;
  }
}

@include media-breakpoint-up(lg) {
  .w-lg-30 {
    width: 30% !important;
  }

  .w-lg-40 {
    width: 40% !important;
  }

  .w-lg-70 {
    width: 70% !important;
  }
}

.alert-box {
  .modal-dialog {
    .modal-content {
      border: none;
      border-radius: 16px;
      padding: 40px 32px;
    }
  }
}

.secondary-text {
  color: $secondary-color;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}

.form-check-label {
  color: $big-text-color;
  font-weight: 600;
}

.form-check-input[type="checkbox"] {
  border-radius: 0.25em;
  border: 0;
  padding: 4px;
  box-shadow: 0 0 0 2px $big-text-color;

  &:focus {
    box-shadow: 0 0 0 2px $big-text-color;
  }
}

.form-check-input:checked {
  background-color: $big-text-color;
}

.text-justify {
  text-align: justify;
}

// toggle button style
.toggle-switch {
  position: relative;
  display: inline-block;
  width: 60px;
  height: 34px;

  input {
    opacity: 0;
    width: 0;
    height: 0;
  }
}

.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  border: 2px solid black;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

.slider:before {
  position: absolute;
  content: "";
  height: 24px;
  width: 24px;
  left: 4px;
  bottom: 4px;
  border: 2px solid black;
  background-color: white;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

input:checked+.slider {
  background-color: #21f360;
}

input:checked+.slider:before {
  -webkit-transform: translateX(24px);
  -ms-transform: translateX(24px);
  transform: translateX(24px);
}

/* Rounded sliders */
.slider.round {
  border-radius: 34px;
}

.slider.round:before {
  border-radius: 50%;
}

.blend-bg {
  background-color: #fafbfe;
}

.custom-multi-select {
  input {
    box-shadow: none !important;
  }

  .react-select__control {
    border-radius: 4px !important;
    min-height: 56px;
    border: 0;
    box-shadow: 0 0 0 2px #5e5e5e;
    font-family: "Nunito";
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 20px;
    letter-spacing: -0.5px;
    color: #313c69;
    max-height: 100px;
    overflow: auto;

    &:focus {
      background-color: rgba(247, 231, 253, 0.286);
      box-shadow: 0px 0px 0px 2px $primary-color !important;
    }

    .react-select__value-container {
      height: 100% !important;
      padding: 8px !important;
      overflow-y: auto;
    }

    .css-12jo7m5 {
      font-size: 100%;
    }

    .css-jzldcf-Input {
      margin: 0 !important;
      padding: 0 !important;
    }

    .css-6j8wv5-Input {
      margin: 0 !important;
      padding: 0 !important;
    }
  }
}

.contest-card {
  box-shadow: 0 0 8px 2px #57565645;
  border-radius: 8px;
  margin-bottom: 8px;
}

table,
th,
td {
  border-collapse: collapse;
  border: 2px solid grey;
}

th,
td {
  text-align: center;
  padding: 8px;
  vertical-align: middle;
}

tr:nth-child(even) {
  background-color: #f2f2f2;
}

th {
  background-color: $primary-color !important;
  color: white;
}

a {
  color: blue !important;
}

.table-responsive {
  flex-grow: 1;
}